/* eslint-disable snakecasejs/snakecasejs */
import { connectSearchBox, connectRefinementList } from 'instantsearch.js/es/connectors';

export { customSearchBox, customRefinementList };

const renderSearchBox = ( render_options, is_first_render ) => {
	const {
		query,
		refine,
		clear,
		widgetParams,
	} = render_options;

	const container = document.querySelector( widgetParams.container );


	if ( is_first_render ) {
		const form = document.createElement( 'form' );
		const input = document.createElement( 'input' );
		const submit_button = document.createElement( 'button' );
		const clear_button = document.createElement( 'button' );


		form.setAttribute( 'role', 'search' );
		form.classList = widgetParams.cssClasses.form;

		input.setAttribute( 'type', 'search' );
		input.setAttribute( 'placeholder', widgetParams.placeholder );
		input.setAttribute( 'autocomplete', 'off' );
		input.setAttribute( 'autocorrect', 'off' );
		input.setAttribute( 'autocapitalize', 'off' );
		input.setAttribute( 'spellcheck', 'false' );
		input.classList = widgetParams.cssClasses.input;

		clear_button.setAttribute( 'aria-label', 'Clear search' );
		clear_button.classList = widgetParams.cssClasses.reset;
		clear_button.classList.add( 'removed' );
		clear_button.setAttribute( 'type', 'button' );

		submit_button.classList = widgetParams.cssClasses.submit;
		submit_button.setAttribute( 'type', 'button' );
		submit_button.setAttribute( 'aria-label', 'Search' );

		input.addEventListener( 'input', event => {
			refine( event.target.value );
			clear_button.classList.remove( 'removed' );
		} );

		clear_button.addEventListener( 'click', () => {
			clear();
			clear_button.classList.add( 'removed' );
		} );

		form.addEventListener( 'submit', ( e ) => {
			e.preventDefault();
		} );

		container.appendChild( form );
		form.appendChild( input );
		form.appendChild( submit_button );
		form.appendChild( clear_button );
	}

	// Render the widget
	container.querySelector( 'input' ).value = query;

};

const customSearchBox = connectSearchBox(
	renderSearchBox
);

const customRefinementList = connectRefinementList( ( renderOptions ) => {
	/* eslint-disable indent */
	const { items, canRefine, refine, widgetParams } = renderOptions;

	const container = widgetParams.container;
	if ( !container ) {
		return;
	}

	if ( !canRefine ) {
		container.innerHTML = '';
		return;
	}

	const html = `
		<h2 class="search-box__filters__heading">Filter</h2>
		<ul class="filter-list__items"> ` +
			items.map(
				item => `${ item.count ?
					`<li class="filter-list__item ${ item.isRefined ? 'filter-list__item--selected' : '' }">
						<label class="filter-list__item-label filter-list__item-label--search">
							<input type="checkbox" class="filter-list__item-checkbox" value="${ item.value }" ${ item.isRefined ? 'checked' : '' } data-aria-label="Filter by ${ item.label }">
							<span class="filter-list__item-checkbox-custom"></span>
							<span class="filter-list__item-label-text">${ item.label }</span>
						</label>
					</li>`
					: '' }`
			)
			.join( '' );
		`</ul>
	`;

	container.innerHTML = html;

	container.querySelectorAll( '.filter-list__item-checkbox' ).forEach( input => {
		input.addEventListener( 'click', event => {
			refine( event.currentTarget.value );
		} );
	} );
} );
