
export function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}

export function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}

export function getScrollY() {
	return ( window.scrollY || document.documentElement.scrollTop );
}

export function getScrollYSearchBox() {
	const search_box_overlay = document.querySelector( '.search-box' );
	return ( search_box_overlay.scrollTop );
}

export function doToggle( toggler, class_override ) {
	const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
	for ( let i = 0; i < togglees.length; i += 1 ) {
		togglees[i].classList.toggle( class_override ? class_override : 'toggled', !togglees[i].classList.contains( 'toggled' ) );
	}
	toggler.classList.toggle( class_override ? class_override : 'toggler--toggled',  class_override ? !toggler.classList.contains( class_override ) : !toggler.classList.contains( 'toggler--toggled' ) );
}

export function doSearchToggle( toggler ) {
	const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
	const search_togglers = document.querySelectorAll( '.search-toggler' );
	for ( let i = 0; i < togglees.length; i += 1 ) {
		togglees[i].classList.toggle( 'search-toggled', !togglees[i].classList.contains( 'search-toggled' ) );
	}
	search_togglers.forEach( toggler => {
		toggler.classList.toggle( 'toggler--toggled', !toggler.classList.contains( 'toggler--toggled' ) );
	} );
}
